import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  getComapareList,
  getComparisonDetails,
  getComparisonDetailsCost,
  getRefineFilterData,
  getSKUDetails,
} from "../../services/compareTableService";
import { getUTFOptions } from "../../services/utilService";

const defaultShowCollapsed = {
  rm: [],
  pm: [],
  capacityUtilization: [],
};

const initialState = {
  isCompareTableLoading: false,
  compareTableData: [],
  refinementFilterOptions: {
    psr: [
      { label: "(+/-) 20%", value: 0.2 },
      { label: "(+/-) 40%", value: 0.4 },
      { label: "(+/-) 60%", value: 0.6 },
      { label: "(+/-) 80%", value: 0.8 },
      { label: "(+/-) 100%", value: 1.0 },
      { label: "(+/-) 120%", value: 1.2 },
    ],
    minMatch: [
      { label: "< 10%", value: 0.0 },
      { label: "10%", value: 0.1 },
      { label: "20%", value: 0.2 },
      { label: "30%", value: 0.3 },
      { label: "40%", value: 0.4 },
      { label: "50%", value: 0.5 },
      // { label: "60%", value: "60" },
      // { label: "70%", value: "70" },
      // { label: "80%", value: "80" },
      // { label: "90%", value: "90" },
      // { label: "100%", value: "100" },
    ],
    maxCCU: [
      { label: "0%", value: "0" },
      { label: "10%", value: "10" },
      { label: "20%", value: "20" },
      { label: "30%", value: "30" },
      { label: "40%", value: "40" },
      { label: "50%", value: "50" },
      { label: "60%", value: "60" },
      { label: "70%", value: "70" },
      { label: "80%", value: "80" },
      { label: "90%", value: "90" },
      { label: "100+%", value: "100" },
    ],
    utf: getUTFOptions(),
    mh: [
      { label: "Commodity Match", value: "commodity" },
      { label: "PLM Match", value: "plm" },
      //{ label: "Component Match", value: "component" },
    ],
    sortBy: [
      { label: "Capacity (Descending)", value: "Capacity Descending" },
      { label: "Capacity (Ascending)", value: "Capacity Ascending" },
      { label: "Cost (Descending)", value: "Cost Descending" },
      { label: "Cost (Ascending)", value: "Cost Ascending" },
      { label: "RM (Descending)", value: "RM Descending" },
      { label: "RM (Ascending)", value: "RM Ascending" },
      { label: "PM (Descending)", value: "PM Descending" },
      { label: "PM (Ascending)", value: "PM Ascending" },
    ],
  },
  initialRefinementFilterValues: {
    psr: 0.8,
    minMatch: 0.5,
    maxCCU: "100",
    utf: [],
    mh: "commodity",
    sortBy: "RM Descending",
  },
  refinementFilterValues: {
    psr: "80",
    minMatch: "50",
    maxCCU: "100",
    utf: [],
    mh: "commodity",
    sortBy: "RM Descending",
  },
  isComparisonDataLoading: false,
  isComparisonCostDataLoading: false,
  comparisonData: {},
  comparisonCostData: {},
  showCollpased: defaultShowCollapsed,
};

export const fetchCompareTableData = createAsyncThunk(
  "fetch/compareTableData",
  async (payload) => {
    const response = await getComapareList(payload);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchRefineFilterData = createAsyncThunk(
  "fetch/refineFilterData",
  async (payload) => {
    const response = await getRefineFilterData(payload);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchComparisonDetails = createAsyncThunk(
  "fetch/comparisonDetails",
  async (payload) => {
    const response = await getComparisonDetails(payload);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);
export const fetchComparisonDetailsCost = createAsyncThunk(
  "fetch/comparisonDetailsCost",
  async (payload) => {
    const response = await getComparisonDetailsCost(payload);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const fetchSKUCompareDetails = createAsyncThunk(
  "fetch/skuCompareDetails",
  async (payload) => {
    const response = await getSKUDetails(payload);
    if (response.success) {
      return response.data;
    }
    return {};
  }
);

export const compareTableSlice = createSlice({
  name: "compareTable",
  initialState,
  reducers: {
    updateRefinementFilterValues: (state, action) => {
      state.refinementFilterValues = action.payload;
    },
    resetRefinementFilterValues: (state) => {
      state.refinementFilterValues = state.initialRefinementFilterValues;
    },
    updateCollapsed: (state, action) => {
      state.showCollpased = { ...state.showCollpased, ...action.payload };
    },
    resetShowCollapsed: (state) => {
      state.showCollpased = defaultShowCollapsed;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCompareTableData.pending, (state) => {
      state.isCompareTableLoading = true;
    });
    builder.addCase(fetchCompareTableData.fulfilled, (state, action) => {
      state.isCompareTableLoading = false;
      state.compareTableData = action.payload;
    });
    builder.addCase(fetchCompareTableData.rejected, (state, action) => {
      state.isCompareTableLoading = true;
      state.error = action.error.message;
    });

    //refine filter reducer
    builder.addCase(fetchRefineFilterData.pending, (state) => {
      state.isCompareTableLoading = true;
    });
    builder.addCase(fetchRefineFilterData.fulfilled, (state, action) => {
      state.isCompareTableLoading = false;
      state.compareTableData = action.payload;
    });
    builder.addCase(fetchRefineFilterData.rejected, (state, action) => {
      state.isCompareTableLoading = true;
      state.error = action.error.message;
    });

    builder.addCase(fetchComparisonDetails.pending, (state) => {
      state.isComparisonDataLoading = true;
    });
    builder.addCase(fetchComparisonDetails.fulfilled, (state, action) => {
      state.isComparisonDataLoading = false;
      state.comparisonData = action.payload;
    });
    builder.addCase(fetchComparisonDetails.rejected, (state, action) => {
      state.isComparisonDataLoading = true;
      state.error = action.error.message;
    });

    builder.addCase(fetchComparisonDetailsCost.pending, (state) => {
      state.isComparisonCostDataLoading = true;
    });
    builder.addCase(fetchComparisonDetailsCost.fulfilled, (state, action) => {
      state.isComparisonCostDataLoading = false;
      state.comparisonCostData = action.payload;
    });
    builder.addCase(fetchComparisonDetailsCost.rejected, (state, action) => {
      state.isComparisonCostDataLoading = true;
      state.error = action.error.message;
    });

    builder.addCase(fetchSKUCompareDetails.pending, (state) => {
      state.isComparisonDataLoading = true;
    });
    builder.addCase(fetchSKUCompareDetails.fulfilled, (state, action) => {
      state.isComparisonDataLoading = false;

      const updatedComparisonAltData =
        state.comparisonData?.alternativeIntialData?.alternative?.map(
          (plant) => {
            if (plant.plantCode === action.meta.arg.alternativePlantCode) {
              const payload = _.omit(action.payload, ["sku"]);
              return { ...plant, ...payload };
            }

            return plant;
          }
        );

      state.comparisonData.alternativeIntialData.alternative =
        updatedComparisonAltData;
    });
    builder.addCase(fetchSKUCompareDetails.rejected, (state, action) => {
      state.isComparisonDataLoading = true;
      state.error = action.error.message;
    });
  },
});

export const {
  updateRefinementFilterValues,
  resetRefinementFilterValues,
  updateCollapsed,
  resetShowCollapsed,
} = compareTableSlice.actions;

export default compareTableSlice.reducer;
