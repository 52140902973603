import { Button, Card, Radio, Space, Table, Typography, Select } from "antd";
//import Select from "react-select";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { ReactSession } from "react-client-session";
import GreenDot from "../../assets/img/green-dot.svg";
import RedDot from "../../assets/img/red-dot.svg";
import YellowDot from "../../assets/img/yellow-dot.svg";
import { fetchCompareTableData,   fetchRefineFilterData} from "../../redux/reducer/compareTableSlice";
import { alterateAlternativeResult, getPathPrefix, removeDuplicates } from "../../services/utilService";

const { Text } = Typography;

const ComparisionTable = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const layer = ReactSession.get("role");
  const { isCompareTableLoading, compareTableData, refinementFilterValues } =
    useSelector((state) => state.compareTable);
  const type = refinementFilterValues.mh;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [checkedRadioKey, setCheckedRadioKey] = useState(1);
  const [selectedDCRowKeys, setSelectedDCRowKeys] = useState([]);
  const isDistributionScreen =
    searchParams.get("distributionCenter") !== "null" ? true : false;

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [listchange, setlistchange] = useState([]);
  const {
    skuFilterOptions,
  } = useSelector((state) => state.search);
  const {
    refinementFilterOptions,
  } = useSelector((state) => state.compareTable);

  let divisionLable = "";
  let CategoryLable = "";
  let productLable;
  refinementFilterOptions?.psr?.map((option) => {
    const key = Object.keys(option)[0];
    const lable = Object.values(option)[0];
  })
  skuFilterOptions?.Product?.map((option) => {
    productLable = Object.keys(option)[0];
  })
  skuFilterOptions?.Division?.map((option) => {
    const lable = Object.values(option)[0];
    divisionLable = lable.toString().split('-')[0].trim()
  })
  skuFilterOptions?.Category?.map((option) => {
    const lable = Object.values(option)[0];
    CategoryLable = lable.toString().split('-')[0].trim()
  })

  useEffect(() => {
    const TargetSKU = productLable;
    const CategoryName = CategoryLable;
    const SubSectorName = skuFilterOptions?.Subsector_Selected?.map((row) => Object.values(row)[0].toString().split('-')[0].trim());
    const FormName = skuFilterOptions?.Form_Selected?.map((row) => Object.values(row)[0].toString().split('-')[0].trim());
    const DivisionName = divisionLable;
    if (TargetSKU && CategoryName && SubSectorName && FormName && DivisionName) {
      const payload = {
        dataCode: searchParams.get("product"),
        corporateBrandCode: searchParams.get("brand") || null,
        skuDescription: searchParams.get("productDesc"),
        type: null,
        filterDropdownData: `'${searchParams.get("division")}'` || "null",
        distributionCenter:
          searchParams.get("distributionCenter") === "null"
            ? null
            : searchParams.get("distributionCenter"),
        params: {
          TargetSKU,
          CategoryName,
          SubSectorName,
          FormName,
          DivisionName,
          PercCountPC: "0.5",
          PercCountPLM: "0.0",
          Pack_Size: "0.8"
        }
      };
      const payload2 = {
        // type:null,
        // dataCode: searchParams.get("product"),
        // filterDropdownData: `'${searchParams.get("division")}'` || "null",
        targetSKU: TargetSKU,
        dimensionRange: Number(refinementFilterValues.psr),
        matchPercentageRange: Number(refinementFilterValues.minMatch),
        capacityRange: refinementFilterValues.maxCCU,
        costRange: null,
        distributionCenter:  searchParams.get("distributionCenter") === "null"
        ? null
        : searchParams.get("distributionCenter"),
        
        rmCommMatchSort: refinementFilterValues?.mh === 'commodity' ? (refinementFilterValues?.sortBy === 'RM Descending' ? 'descending' : (refinementFilterValues?.sortBy === 'RM Ascending' ? 'ascending' : null)) : null,
        pmCommMatchSort: refinementFilterValues?.mh === 'commodity' ? (refinementFilterValues?.sortBy === 'PM Descending' ? 'descending' : (refinementFilterValues?.sortBy === 'PM Ascending' ? 'ascending' : null)) : null,
        rmCompMatchSort: null,
        pmCompMatchSort: null,
        plmRMMatchSort: refinementFilterValues?.mh === 'plm' ? (refinementFilterValues?.sortBy === 'RM Descending' ? 'descending' : (refinementFilterValues?.sortBy === 'RM Ascending' ? 'ascending' : null)) : null,
        plmPMMatchSort: refinementFilterValues?.mh === 'plm' ? (refinementFilterValues?.sortBy === 'PM Descending' ? 'descending' : (refinementFilterValues?.sortBy === 'PM Ascending' ? 'ascending' : null)) : null,
        capacityAscending:refinementFilterValues?.sortBy === 'Capacity Ascending' ? 'ascending' : null,
        capacityDescending:refinementFilterValues?.sortBy === 'Capacity Descending' ? 'descending' : null,
        costAscending:refinementFilterValues?.sortBy === 'Cost Ascending' ? 'ascending' : null,
        costDescending:refinementFilterValues?.sortBy === 'Cost Descending' ? 'descending' : null,
        match: refinementFilterValues.mh,
        date: refinementFilterValues.utf,
        params: {
          TargetSKU: productLable,
          CategoryName: CategoryLable,
          SubSectorName: skuFilterOptions?.Subsector_Selected?.map((row) => Object.values(row)[0].toString().split('-')[0].trim()),
          FormName: skuFilterOptions?.Form_Selected?.map((row) => Object.values(row)[0].toString().split('-')[0].trim()),
          DivisionName: divisionLable,
          
          PercCountPC: refinementFilterValues.mh == "commodity" ? Number(refinementFilterValues.minMatch /100): 0,
          PercCountPLM: refinementFilterValues.mh == "plm" ? Number(refinementFilterValues.minMatch /100) : 0,
          Pack_Size:refinementFilterValues.psr /100
        }
      };
      // dispatch(fetchCompareTableData(payload2));
      dispatch(fetchRefineFilterData(payload2));
    }

  }, [dispatch, searchParams, skuFilterOptions]);

  useEffect(() => {
    if (compareTableData?.Alternative?.length > 0) {
      setCheckedRadioKey(
        compareTableData.Alternative.findIndex(
          (plant) => plant["level"] === "Current_Parent"
        ) + 1
      );
    }
    let a = []
    compareTableData?.Alternative?.map(doc => a.push({ 'value': doc.MatchBrand }))
    setList(removeDuplicates(a, 'value'))
  }, [compareTableData]);


  //New Code
  useEffect(() => {
    if (compareTableData?.Alternative?.length > 0) {
      const data = alterateAlternativeResult(compareTableData.Alternative)
      if (selectedOptions.length == 0) {
        setlistchange(data.map((value, index) => {
          return { ...value, key: index + 1 };
        }))
      }
      else {
        setlistchange(data.filter(d => d.MatchBrand.some(value => selectedOptions.includes(value))).map((value, index) => {
          return { ...value, key: index + 1 };
        }))
      }
    }
  }, [selectedOptions, compareTableData]);

  const compare = () => {
    const searchParams1 = {
      skuDescription: searchParams.get("productDesc"),
      tarketSKUCode: searchParams.get("product"),
      tarketPlantCode:
        compareTableData?.Alternative?.find(
          (_row, index) => checkedRadioKey === index + 1
        )?.PlantCode || null,
      alternativePlantCode: selectedRows.map((row) => row.PlantCode).join(),
      brandCode: searchParams.get("brand"),
      distributionCenter: searchParams.get("distributionCenter"),
      selectedDC: [],
      type: refinementFilterValues.mh,
      matchPer: refinementFilterValues.minMatch,
      dimension: refinementFilterValues.psr,
      capacityRange: refinementFilterValues.maxCCU,
      date: refinementFilterValues.utf,
      dcPlant: searchParams.get("distributionCenter"),
    };

    navigate({
      pathname: getPathPrefix("/compare/sourcing-units-details"),
      search: `?${createSearchParams(searchParams1)}`,
    });
  };

  const onCheckboxChange = (keys, rows) => {
    setSelectedRowKeys(keys);
    setSelectedRows(rows);
  };

  const onDCCheckboxChange = (selectedRowKeys, selectedRows) => {
    setSelectedDCRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    type: "checkbox",
    selectedRowKeys,
    onChange: onCheckboxChange,
    columnTitle: "Select to Compare",
    columnWidth: 60,
  };

  const rowSelectionDC = {
    type: "radio",
    selectedDCRowKeys,
    onChange: onDCCheckboxChange,
    columnTitle: "Reference Sourcing Unit",
    columnWidth: 120,
  };

  const columns = [
    {
      title: "Reference Sourcing Unit",
      align: "center",
      key: 1,
      width: 100,
      className: "table-col-white-border",
      render: (_, record) => {
        return (
          <>
            {record.level === "Current_Parent" && (
              <Radio
                checked={checkedRadioKey === record.key}
                onChange={(e) => {
                  setCheckedRadioKey(record.key);
                }}
              />
            )}
          </>
        );
      },
    },
    {
      title: "SU Unit & Location",
      align: "left",
      key: "SU-Unit",
      width: 300,
      className: "table-col-white-border",
      render: (_, record) => (
        <>
          <Text style={{ fontSize: 16, fontFamily: "UnileverShillingMedium" }}>
            {record?.PlantName + "-" + record?.PlantCode}
          </Text>
          <br></br>
          <Text>{record?.country + " | " + record?.City}</Text>
        </>
      ),
    },
    {
      title: "SKU Information",
      align: "left",
      key: "SKU-Info",
      width: 200,
      className: "table-col-white-border",
      render: (_, record) => (
        <>
          <Text strong>{record?.Dimension}</Text>
          <br></br>
          <Text>{record?.NumberOfMatchSKU} Similar SKU</Text>
        </>
      ),
    },
    {
      title: "Raw Material Match",
      align: "center",
      key: "Raw-Material",
      width: 100,
      className: "table-col-white-border",
      render: (_, record) => {
        let matchPercentage = record.plmRMMatchPercentage;
        if (type === "commodity") {
          matchPercentage = record.RMCommodityMatchPercent;
        } else if (type === "component") {
          matchPercentage = record?.RMComponentMatchPercentage;
        }
        return (
          <div
            style={{
              display: "flex",
              gap: 4,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              src={
                matchPercentage === 100
                  ? GreenDot
                  : matchPercentage >= 50
                    ? YellowDot
                    : RedDot
              }
              alt="percentage"
            />
            {matchPercentage} %
          </div>
        );
      },
    },
    {
      title: "Package Material Match",
      align: "center",
      key: "package-Material",
      className: "table-col-white-border",
      width: 100,
      render: (_, record) => {
        let matchPercentage = record.plmPMMatchPercentage;
        if (type === "commodity") {
          matchPercentage = record.PMCommodityMatchPercentage;
        } else if (type === "component") {
          matchPercentage = record?.PMComponentMatchPercentage;
        }
        return (
          <div
            style={{
              display: "flex",
              gap: 4,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              src={
                matchPercentage === 100
                  ? GreenDot
                  : matchPercentage >= 50
                    ? YellowDot
                    : RedDot
              }
              alt="percentage"
            />
            {matchPercentage} %
          </div>
        );
      },
    },
    {
      title: "Ex-Works Cost",
      align: "center",
      key: "Ex-Works",
      width: 100,
      className: "table-col-white-border",
      render: (_, record) => {
        let showCost =
          layer !== "NonDivisionUser" &&
          record.minExWorks !== 0 &&
          record.maxExWorks !== 0;
        if (!showCost) {
          return "NA";
        }
        return (
          <Text>
            {record.minExWorks === record.maxExWorks
              ? `€${record.minExWorks}`
              : `€${record.minExWorks} - €${record.maxExWorks}`}
          </Text>
        );
      },
    },
    {
      title: "Category Utilization",
      align: "center",
      key: "Category-Utilization",
      width: 100,
      className: "table-col-white-border",
      render: (_, record) => {
        let isNonDivisionUser = layer === "NonDivisionUser";
        if (isNonDivisionUser) {
          return "NA";
        }
        return (
          <div
            style={{
              display: "flex",
              gap: 4,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              src={
                record?.capacityCategory >= 80
                  ? RedDot
                  : record?.capacityCategory > 25
                    ? YellowDot
                    : GreenDot
              }
              alt="percentage"
            />
            {record.capacityCategory} %
          </div>
        );
      },
    },
    {
      title: "Avg Capacity Utilization",
      align: "center",
      key: "Avg-Capacity",
      width: 100,
      className: "table-col-white-border",
      render: (_, record) => {
        let isNonDivisionUser = layer === "NonDivisionUser";
        if (isNonDivisionUser) {
          return "NA";
        }
        return (
          <div
            style={{
              display: "flex",
              gap: 4,
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img
              src={
                record?.capacity >= 80
                  ? RedDot
                  : record?.capacity >= 50
                    ? YellowDot
                    : GreenDot
              }
              alt="percentage"
            />
            {record.capacity} %
          </div>
        );
      },
    },
  ];

  //Remove the first col for DC table
  if (isDistributionScreen) {
    columns.shift();
  }

  const columnsDC = [
    {
      title: "SU Unit & Location",
      align: "left",
      key: "SU-Unit",
      width: 500,
      className: "table-col-white-border",
      render: (_, record) => (
        <>
          <Text style={{ fontSize: 16, fontFamily: "UnileverShillingMedium" }}>
            {record?.PlantName + "-" + record?.PlantCode}
          </Text>
          <br></br>
          <Text>{record?.country + " | " + record?.City}</Text>
        </>
      ),
    },
    {
      title: "Transfer Price with Temperature Control",
      align: "left",
      key: "TransferPrice",
      className: "table-col-white-border",
      render: (_, record) => {
        return "-";
      },
    },
    {
      title: "Transfer Price without Temperature Control",
      align: "left",
      key: "TransferPriceWithout",
      className: "table-col-white-border",
      render: (_, record) => {
        return "-";
      },
    },
  ];

  const [list, setList] = useState([])
  function handleSelect(data) {
    setSelectedOptions(data);
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {isDistributionScreen && (
        <Card bordered>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 16,
            }}>
            <div>
              <Text
                style={{
                  marginBottom: 0,
                  fontSize: 16,
                  fontFamily: "UnileverShillingMedium",
                }}>
                Select one SU as Reference Route
              </Text>
              <br></br>
              <Text strong style={{ fontSize: "14px" }}>
                Logistics Cost
              </Text>
              <Text style={{ fontSize: "14px" }}> (Existing Routes)</Text>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  marginRight: "12px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "end",
                }}>
                <Text strong>Cost Valid From:</Text>
                <Text>{`${compareTableData?.currentDateFrom} to ${compareTableData?.currentDateTo}`}</Text>
              </div>

              <Button
                type="primary"
                onClick={compare}
                disabled={isCompareTableLoading || selectedRows.length < 2}>
                Compare
              </Button>
            </div>
          </div>
          <Table
            tableLayout="fixed"
            rowSelection={rowSelectionDC}
            columns={columnsDC}
            dataSource={
              Array.isArray(compareTableData?.Alternative) && Array.from(
                compareTableData?.Alternative
                  ?.filter(d => d.isAvailable)
                  .reduce((map, obj) => {
                    if (!map.has(obj.PlantCode)) {
                      map.set(obj.PlantCode, obj);
                    }
                    return map;
                  }, new Map())
                  .values()
              ).map((value, index) => {
                return { ...value, key: index + 1 };
              })
            }
            //dataSource={listchange}
            pagination={false}
            loading={isCompareTableLoading}
            scroll={{ x: "max-content" }}
            className="su-table"
            rowClassName={(record, index) =>
              index % 2 === 0 ? "" : "table-row-dark"
            }
          />
        </Card>
      )}
      <Card bordered>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 16,
          }}>
          <div>
            <Text
              style={{
                marginBottom: 0,
                fontSize: 16,
                fontFamily: "UnileverShillingMedium",
              }}>
              Select at least 2 Sourcing Units for Comparison
            </Text>
            {isDistributionScreen && (
              <div style={{ fontSize: "14px", fontWeight: 600 }}>
                Ex Works Cost
              </div>
            )}
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Select
              options={list}
              placeholder="Select Match Brand"
              value={selectedOptions}
              onChange={handleSelect}
              style={{
                width: 200,
                color: "#292929",
              }}
              // isSearchable={true}
              mode="multiple"
            />
            <div
              style={{
                marginRight: "12px",
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}>
              <Text strong>Cost Valid From:</Text>
              <Text>{`${compareTableData?.currentDateFrom} to ${compareTableData?.currentDateTo}`}</Text>
            </div>

            <Button
              type="primary"
              onClick={compare}
              disabled={isCompareTableLoading || selectedRows.length < 2}>
              Compare
            </Button>
          </div>
        </div>
        <Table
          tableLayout="fixed"
          rowSelection={rowSelection}
          columns={columns}
          // dataSource={compareTableData?.Alternative?.map((value, index) => {
          //   return { ...value, key: index + 1 };
          // })}
          dataSource={listchange}
          pagination={false}
          loading={isCompareTableLoading}
          scroll={{ x: "max-content" }}
          className="su-table"
          rowClassName={(record, index) =>
            index % 2 === 0 ? "" : "table-row-dark"
          }
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "16px",
          }}>
          <Button
            type="primary"
            onClick={compare}
            disabled={isCompareTableLoading || selectedRows.length < 2}>
            Compare
          </Button>
        </div>
      </Card>
    </Space>
  );
};

export default ComparisionTable;
